(function ($) {
    // On DOM ready
    $(document).ready(function () {
    var lang, href;
    lang = $('html').attr('lang');

    // Check relative url have translated path
    // $('section.overview a, .accordian .box a, .tabs a, .accordion-ibm a, .paragraph--type--html-layout a').each(function() {
    $('.accordian .box a, .masonary-grid .box ul a').each(function() {
      href = $(this).attr('href');
      // console.log(href);
      if (lang != 'en' && (href.indexOf('https') === -1)) {
        if ((href.indexOf(lang + '/') > 0)) {
          console.log('This URL have language path', href);
        } else {
          console.log('This URL have missing language path', href);
          // $(this).attr('href', '/' + lang + href);
          $.ajax({
            type: 'HEAD',
            url: '/' + lang + href,
            context: this,
            success: function() {
              // page exists
              // console.log('This URL have translated page', href);
              // $(this).attr('href', '/' + lang + href);
              $(this).addClass('lang');
            },
            error: function() {
              // page does not exist
              // console.log('This URL have missing translated page [404]', href);
              // $(this).addClass('removelang');
            }
          });
        }
      }
    });

      // Force external links to open in new tab / window
      var siteName = window.location.host.replace('www.', '');
      $('a[href^="htt"]:not([target]):not([href*="www.' + siteName + '"]):not([href*="//' + siteName + '"]):not([class*="fancybox"])').attr('target', '_blank');


      new WOW().init();
      objectFitImages();


      var length = $('body #block-thalesesecurity-local-tasks ul li').length;
      if (length > 4) {
        $('body #block-thalesesecurity-local-tasks ul li:nth-last-child(2)').hide();
      }
    });

  $(window).on('load', function() {
    var lang, href2;
    lang = $('html').attr('lang');
    // $("section.overview a.lang, .accordian .box a.lang, .tabs a.lang, .accordion-ibm a.lang, .paragraph--type--html-layout a.lang ").each(function() {
    $('.accordian .box a.lang, .masonary-grid .box ul a.lang').each(function() {
      href2 = $(this).attr('href');
      // console.log(href2);
      $(this).attr('href', '/' + lang + href2);
    });

  });


    // For window load and resize
    $(window).on('load resize', function () {
      var win_width = $(window).width();
      if (win_width > 768) {
        $('.page-links .column-wrapper .col-height').matchHeight();
      }

    })
    $(".charts select").change(function() {
      var qlocation = $(this).val();
      if( qlocation != " " ) {
          var qtarget = $('div[id="' + qlocation + '"]').offset().top;
          $("html,body").animate({
              scrollTop: qtarget - 250
          }, 1000);
      }
  });

  $(document).on("change",".questionscroll select",function() {
    var qlocation = $(this).val();
    if( qlocation != " " ) {
      var qtarget = $('div[id="' + qlocation + '"]').offset().top;
      $("html,body").animate({
        scrollTop: qtarget - 100
      }, 1000);
    }
  });
  $(".tt").hover(function() {
		var ttplacement = $(this).attr("data-placement");
    var tttext = $(this).attr("title");
    // console.log(true);
		$(this).after( "<div class='tt-cont tt-" + ttplacement + "'><div class='tt-arrow'></div><div class='tt-text'>" + tttext + "</div></div>" );
		if(ttplacement == "top") {
			var textwidth = $(this).next().find(".tt-text").width();
			var wcalc = -25 - (textwidth /2);
			$(this).next().find(".tt-text").css("left", wcalc);
		}
        if(ttplacement == "left") {
			var textwidth = $(this).next().find(".tt-text").width();
			var wcalc = -textwidth -28;
			$(this).next().find(".tt-text").css("left", wcalc);
		}
	}, function() {
		$(this).next().remove();
	});

  $(".smoothscroll").click(function( e ) {
    var slocation = $(this).attr("href");
    if(slocation.charAt(0) == "#") {
        e.preventDefault();
        var starget = $(slocation).offset().top - 165;
        $("html,body").stop().animate({
            scrollTop: starget
        }, 600);
    }
  });
  function countup(a, b, c) {
    var counter = 0;
    var looper = setInterval(function() {
      counter++;
      $(".counter-" + a).html(counter);
      if (counter == c[a]) {
        clearInterval(looper);
      }
    }, b);
  }

  $('.introduction-section .btn-default').on('click',function(){
    $(this).parents('.introduction-section').slideUp();
    setTimeout(function(){
      $('#form').slideDown();
    },300)
  })
  var totalcount = [];
  var numpos = [];
  var winheight = $(window).height() / 1.5;
  var countnum = 0;
  var x = 0;
  $(function() {
    // finds each counter number and stores it
    $(".count-up").each( function() {
      totalcount.push($(this).html());
      numpos.push(Math.round($(this).offset().top));
      $(this).html("00");
      $(this).addClass("counter-" + countnum);
      countnum++;
    });

    // detects current scroll position
    $(window).scroll(function () {
      var starget = numpos[x] - winheight;
      var scroll = $(window).scrollTop();
      if( scroll > 140 ) {
        $(".dsci-nav").addClass("sticksubnav");
      }
      if( scroll < 140 ) {
        $(".dsci-nav").removeClass("sticksubnav");
      }
      if( scroll > starget) {
        inialisecountup();
      }
    });
  });
  function inialisecountup() {
    if(!$(".counter-" + x).hasClass("active")) {
      // time it takes to count up
      var calculate = 0.8 / totalcount[x] * 1000;
      countup(x, calculate, totalcount);
    }
    $(".counter-" + x).addClass("active");
    x ++;
  }
  $(".expand").click(function() {
		$(this).toggleClass("minus").prev().slideToggle("fast");
		$(this).prev().prev().slideToggle("fast");
  });

  $('body .year-tabs ul li').on('click',function(){
    var ind = $(this).index();
    $('body .year-tabs ul li').removeClass('active');
    $(this).addClass('active');
    $('.tab-content .tab').removeClass('active');
    $('.tab-content .tab:eq('+ind+')').addClass('active');
  });
  })(jQuery);
