// (function($) {
//   $(document).ready(function(){
//     var len_col = $('body .footer-cta .full-container .full-col').length;
//
//     if(len_col == 1){
//       $('body .footer-cta .full-container .full-col').addClass('single-col');
//     }
//
//   });
// })(jQuery);
